<template>
  <main class="kb-main" id="kb-talentboard-learner">

    <div class="main-content min-component mt-5">
      <section v-if="readies.learn && readies.license" class="content-section">
        <TalentJourney
            :learns="learns"
            :license-codes="licenseCodes"
            :goals="[]"
            :is-opened="true"
            :show-code="true"
            :init-index="2"
        />
      </section>
    </div>
  </main>
</template>

<script>
import TalentJourney from '@/components/mypage/talent/TalentJourney';
import {computed, reactive, ref} from 'vue';
import {getListFunc} from '@/assets/js/ui.init';
import {ACT_GET_LEARN_FINISH_HISTORY_LIST} from '@/store/modules/learn/learn';
import {ACT_GET_LRN_SPRT_LCSN_LIST} from '@/store/modules/license/license';

export default {
  name: 'TestJourney',
  components: {TalentJourney},
  setup() {
    const readies = reactive({
      learn: false,
      license: false,
    });

    const learns = ref([]);
    const licenses = ref([]);
    const licenseCodes = computed(() => licenses.value.map(x => x.lcsnCd));

    getListFunc(`learn/${ACT_GET_LEARN_FINISH_HISTORY_LIST}`, {}, learns, null, () => {
      readies.learn = true;
    });
    getListFunc(`license/${ACT_GET_LRN_SPRT_LCSN_LIST}`, {regYn: 'Y'}, licenses, null, () => {
      readies.license = true;
    });

    return {
      learns,
      licenseCodes,
      readies,
    }
  }
};
</script>
